import React from "react";
import '../CSS/Footer.css';

function Footer() {

    return (
        <footer>
            <p>© 2024 Mesh Architecture</p>
        </footer>
    );
}


export default Footer;