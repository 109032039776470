import React from "react";

function Padding() {

    return (
        <div style={{height: "200px", width: "100%"}  }>
        </div>
    );

}

export default Padding;